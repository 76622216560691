import React from "react";
import "./App.css";
import { Pages } from "./Pages/Pages";

function App() {
    return (
        <div>
            <Pages />
        </div>
    );
}

export default App;
